import { Category } from 'src/app/models/category.model';

export class CategoriesLoaded {
  public static type: string = '[Category] Categories Loaded';

  constructor(public readonly categories: Category[]) {}
}

export class CategoryRemoved {
  public static type: string = '[Category] Category Removed';

  constructor(public readonly id: number) {}
}
export class CategoryAdded {
  public static type: string = '[Category] Category Added';

  constructor(public readonly category: Category) {}
}

export class CategoryUpdated {
  public static type: string = '[Category] Category Updated';

  constructor(
    public readonly category: Partial<Category> & Pick<Category, 'id'>
  ) {}
}

/**
 *
 * publish a bulk categories update
 *
 */
export class CategoriesUpdated {
  public static type: string = '[Category] Categories Updated';

  constructor(
    public readonly categories: (Partial<Category> & Pick<Category, 'id'>)[]
  ) {}
}
