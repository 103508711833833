import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import {
  append,
  compose,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { Category } from 'src/app/models/category.model';
import {
  CategoriesLoaded,
  CategoriesUpdated,
  CategoryAdded,
  CategoryRemoved,
  CategoryUpdated,
} from './category.action';

interface CategoryStateModel {
  categories: Category[];
}

export interface NestedCategory extends Category {
  children: NestedCategory[];
}

@State<CategoryStateModel>({
  name: 'category',
  defaults: {
    categories: [],
  },
})
@Injectable()
export class CategoryState {
  @Selector()
  public static categories(state: CategoryStateModel): Category[] {
    return state.categories;
  }

  /**
   *
   * returns only first level categories
   *
   */
  @Selector([CategoryState.categories])
  public static parents(categories: Category[]): Category[] {
    return categories.filter((category) => !category.parent_id);
  }

  @Selector([CategoryState.categories])
  public static nested(categories: Category[]): NestedCategory[] {
    const parents = categories
      .filter((category) => !category.parent_id)
      .map((category) => {
        return {
          ...category,
          children: this.children(
            category,
            categories.filter((c) => c.breadcrumbs.includes(category.id))
          ),
        };
      })
      .sort((a, b) => a.order - b.order);

    if (parents.length > 0) {
      return parents;
    }

    const firstLevel = categories
      .filter(
        (category) =>
          !categories.some((_category) => _category.id === category.parent_id)
      )
      .map((category) => {
        return {
          ...category,
          children: this.children(
            category,
            categories.filter((c) => c.breadcrumbs.includes(category.id))
          ),
        };
      });

    return firstLevel;
  }

  public static children(
    category: Category,
    categories: Category[]
  ): NestedCategory[] {
    return categories
      .filter((_category) => _category.parent_id === category.id)
      .map((_category) => ({
        ..._category,
        children: this.children(_category, categories),
      }))
      .sort((a, b) => a.order - b.order);
  }

  public static nestedChildrenOf(
    id: number
  ): (categories: Category[]) => NestedCategory[] {
    return createSelector([CategoryState.categories], (categories) => {
      return this.children(
        categories.find((c) => c.id === id)!,
        categories.filter((c) => c.breadcrumbs.includes(id))
      );
    });
  }

  @Selector([CategoryState.categories])
  public static descendantsSelector(
    categories: Category[]
  ): (id: number) => Category[] {
    function descendants(category: Category): Category[] {
      const descendant = categories.find(
        (_category) => _category.id === category.parent_id
      );

      if (!descendant) {
        return [];
      }

      return [descendant, ...descendants(descendant)];
    }

    return (id) => {
      const category = categories.find((category) => category.id === id);

      if (!category) {
        return [];
      }

      return [category].concat(...descendants(category));
    };
  }

  @Action(CategoriesLoaded)
  onCategoriesLoaded(
    ctx: StateContext<CategoryStateModel>,
    action: CategoriesLoaded
  ) {
    return ctx.patchState({ categories: action.categories });
  }
  @Action(CategoryRemoved)
  onCategoryRemoved(
    ctx: StateContext<CategoryStateModel>,
    action: CategoryRemoved
  ) {
    return ctx.setState(
      patch({ categories: removeItem((category) => category.id === action.id) })
    );
  }
  @Action(CategoryAdded)
  onCategoryAdded(
    ctx: StateContext<CategoryStateModel>,
    action: CategoryAdded
  ) {
    return ctx.setState(patch({ categories: append([action.category]) }));
  }

  @Action(CategoryUpdated)
  onCategoryUpdated(
    ctx: StateContext<CategoryStateModel>,
    action: CategoryUpdated
  ) {
    return ctx.setState(
      patch({
        categories: updateItem(
          (category) => category.id === action.category.id,
          patch({ ...action.category })
        ),
      })
    );
  }

  @Action(CategoriesUpdated)
  onCategoriesUpdated(
    ctx: StateContext<CategoryStateModel>,
    action: CategoriesUpdated
  ) {
    return ctx.setState(
      patch({
        categories: compose(
          ...action.categories.map(({ id, ...rest }) =>
            updateItem<Category>((c) => c.id === id, patch({ ...rest }))
          )
        ),
      })
    );
  }
}
